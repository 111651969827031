<template>
    <div class="page-bulk-new-search">
        <div class="board">
            <app-loader v-if="loading || processing"></app-loader>

            <app-input    v-model="bulk.description" label="Name your bulk search"  :error="errors.fields.description" @change="showNextError('description')" :disabled="processing"></app-input>
            <app-textarea v-model="bulk.addresses"   label="Addresses (1 per line)" :error="errors.fields.addresses"   @change="showNextError('addresses')"   :disabled="processing"></app-textarea>

            <app-checkbox-group v-if="theme != 'pb'"
                v-model="bulk.services"

                :options="bulk_services"

                label="Services"

                :disabled="processing"

                key-value="Name"
                key-title="Title"
            />

            <button class="btn btn-primary btn-small" :disabled="processing" @click.prevent="submit">Submit</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckboxGroup from '@/components/app-checkbox-group'

import errMessage from '@/helpers/errMessage'
import BulkValidator from '@/validators/bulk-validator'

export default {
    components: {
        appLoader,
        appInput,
        appTextarea,
        appCheckboxGroup,
    },

    data() {
        return {
            bulk: {
                description: null,
                addresses: null,
                services: [],
            },

            errors: {
                show: false,
                message: '',
                fields: {},
            },

            loading: false,
            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            // this.getBulkServices()
        },

        /*
            getBulkServices() {
                this.loading = true

                this.$store.dispatch('getBulkServices')
                    .then(bulk_services => {
                        this.loading = false
                    })
                    .catch(error => {
                        this.loading = false
                    })
            },
        */

        submit() {
            if (this.validation()) {
                this.processing = true

                const payload = {
                    SPID: this.current_spid,
                    Description: this.bulk.description,
                    SearchTexts: this.bulk.addresses.split('\n'),
                    SearchServices: this.bulk.services,
                }

                this.$store.dispatch('api_bulkprequal/AddBulkPreQualJob', payload)
                    .then(bulk => {
                        this.$router.push({ name: 'bulk-previous' })
                    })
                    .catch(error => {
                        this.processing = false
                    })
            }
        },

        validation() {
            this.errors = {
                show: false,
                message: '',
                fields: {},
            }

            const values = {...this.bulk}

            for (const key in values) {
                if (BulkValidator.isRuleExists(key)) {
                    if (BulkValidator.isInvalid(key, values[key])) {
                        this.errors.fields[ key ] = BulkValidator.getError()
                    }
                }
            }

            return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }

            const errors = Object.keys(this.errors.fields)

            if (errors.length) {
                this.errors.show = true
                this.errors.message = this.errors.fields[ errors[0] ]
            } else {
                this.errors.show = false
            }

            return this.errors.show
        },
    },

    computed: {
        ...mapGetters([
            'theme',
            'current_spid',
            'modules_sp',
            'module_types',

            // 'bulk_services',
        ]),

        bulk_services() {
            const bulk_services = {}

            // console.log('this.modules_sp', JSON.parse(JSON.stringify(this.modules_sp)))

            // console.log('this.theme', this.theme)

            const limited_services = [
                'PROD_FIBRE',
                'PROD_FWA',
            ]

            // console.log('limited_services', limited_services)

            let modules_sp = {}

            if (this.theme == 'pb') {
                for (const key in this.modules_sp) {
                    if (limited_services.indexOf(key) != -1) {
                        modules_sp[key] = this.modules_sp[key]
                    }
                }
            } else {
                modules_sp = this.modules_sp
            }

            // console.log('modules_sp', JSON.parse(JSON.stringify(modules_sp)))

            for (const key in modules_sp) {
                if (this.modules_sp[key].Type == this.module_types.PRODUCT) {
                    bulk_services[this.modules_sp[key].Name] = {
                        Name: this.modules_sp[key].Name,
                        Title: this.modules_sp[key].Title,
                    }
                }
            }

            // console.log('bulk_services', JSON.parse(JSON.stringify(bulk_services)))

            return bulk_services
        },
    },
}
</script>

<style lang="scss">
.page-bulk-new-search {
    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-input,
        .app-textarea {
            width: 655px;
            max-width: 100%;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .app-checkbox-group {
            margin: 40px 0 0;

            .options {
                justify-content: flex-start;

                .app-checkbox {
                    margin-right: 48px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &>.btn {
            margin-top: 30px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-bulk-new-search {
        .board {
            .app-input,
            .app-textarea {
                width: 545px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-bulk-new-search {
        .board {
            padding: 24px 8px;

            .app-input,
            .app-textarea {
                margin-bottom: 24px;
            }

            .app-checkbox-group {
                margin-top: 54px;

                .options {
                    .app-checkbox {
                        margin-right: 32px;
                    }
                }
            }

            &>.btn {
                margin-top: 24px;
            }
        }
    }
}
</style>