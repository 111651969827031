import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    description: {
        name: 'Description',
        required: true,
        custom_message: 'Sorry, that\'s not a valid description. Check the details and try again.',
    },
    addresses: {
        name: 'Addresses',
        required: true,
        custom_message: 'Sorry, that\'s not a valid addresses list. Check the details and try again.',
    },
})